<ion-app>

  <div class="layout-busy-spinner" *ngIf="busyLoading">
    <viva-spinner color="white"></viva-spinner>
  </div>

  <app-main-menu ></app-main-menu>
  <ion-router-outlet id="main-content">

  </ion-router-outlet>
</ion-app>


